import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as localforage from 'localforage';

import * as BeltActions from '../../state/belt/belt.actions';
import * as CustomerActions from '../../state/customer/customer.actions';
import * as SurveyActions from '../../state/survey/survey.actions';
import * as CscActions from '../../state/csc/csc.actions';

import { PromiseQueue } from '../../../app/utils/promise-queue';
import { TranslateService, TranslationData } from '../translate/translate.service';

import { State } from '../../state/core.state';
import { Model } from '../../../app/state/state-utils';
import { Survey } from './../../models/survey.model';
import {
  Belt,
  ModularColour,
  ModularMaterial,
  ModularType,
  SyntheticMaterial,
} from './../../models/belt.model';
import { Customer } from '../../models/customer.model';
import { Csc } from '../../models/csc.model';

enum ForageKeys {
  AMMSCAN_DATA = 'AMMSCAN_DATA',
  TRANSLATION_DATA = 'TRANSLATION_DATA',
}

export class SynchronisationData {
  public customers?: Customer[] = [];
  public surveys?: Survey[] = [];
  public belts?: Belt[] = [];
  public cscs?: Csc[] = [];
  public syntheticMaterials?: SyntheticMaterial[] = [];
  public modularMaterials?: ModularMaterial[] = [];
  public modularTypes?: ModularType[] = [];
  public modularColours?: ModularColour[] = [];
}

@Injectable({ providedIn: 'root' })
export class ForageService {
  private state$ = this.store.select((state: State) => state);

  public constructor(
    private store: Store,
    private promiseQueue: PromiseQueue,
    private translateService: TranslateService
  ) {}

  public async init(): Promise<void> {
    await this.getRetiredLocalDataAndMigrate();
    await this.migrateCustomerData();
    await this.initStateFromForage();
    await this.initTranslationsFromForage();

    this.state$.subscribe((state: State) => {
      this.promiseQueue.push(() => this.saveStateToForage(state));
    });

    this.translateService.subscribeToTranslationData((data: TranslationData) => {
      this.promiseQueue.push(() => this.saveTranslationDataToForage(data));
    });
  }

  private async getRetiredLocalDataAndMigrate(): Promise<void> {
    try {
      const masterData: SynchronisationData = await localforage.getItem('MASTER_DATA');
      const clientData: SynchronisationData = await localforage.getItem('CLIENT_DATA');

      if (!masterData || !clientData) return;

      const fields = Object.keys(masterData);
      const data: SynchronisationData = {};

      for (const field of fields) {
        data[field] = this.mergeClientDataAndMasterData(clientData[field], masterData[field]);
        data[field] = this.replaceOldStateFlagsWithDirtyFlag(data[field]);
      }

      await localforage.setItem(ForageKeys.AMMSCAN_DATA, data);
      await localforage.removeItem('MASTER_DATA');
      await localforage.removeItem('CLIENT_DATA');
    } catch (err) {
      console.log('unable to migrate local data', err);
    }
  }

  private async migrateCustomerData(): Promise<void> {
    const data = await this.getLocalDataByKey(ForageKeys.AMMSCAN_DATA);
    data.customers = this.replaceCustomerUserGroupIdWithId(data.customers);
    await localforage.setItem(ForageKeys.AMMSCAN_DATA, data);
  }

  private mergeClientDataAndMasterData<T extends Model>(clientData: T[], masterData: T[]): T[] {
    const result: T[] = [];
    const clientItemMap: { [id: string]: T } = {};
    const masterItemMap: { [id: string]: boolean } = {};

    for (const item of clientData) clientItemMap[item.id] = item;

    for (const masterItem of masterData) {
      const clientItem = clientItemMap[masterItem.id];
      if (clientItem) {
        result.push(this.selectItemUsingMergeLogic(masterItem, clientItem));
      } else {
        result.push(masterItem);
      }
      masterItemMap[masterItem.id] = true;
    }

    for (const item of clientData) {
      if (!masterItemMap[item.id]) result.push(item);
    }

    return result;
  }

  private selectItemUsingMergeLogic<T extends Model>(masterItem: T, clientItem: T): T {
    if (masterItem.deleted && !clientItem.deleted) return masterItem;
    return clientItem;
  }

  private replaceOldStateFlagsWithDirtyFlag(items: Model[]): Model[] {
    return items.map((item) => {
      item.dirty = item.dirty ?? item['hasLocalChange'] ?? undefined;
      delete item['hasLocalChange'];
      delete item['hasServerRecord'];
      return item;
    });
  }

  private replaceCustomerUserGroupIdWithId(customers): Customer[] {
    return customers.map((customer) => {
      customer.id = customer.id ?? customer.user_group_id;
      delete customer.user_group_id;
      return customer;
    });
  }

  public async initStateFromForage(): Promise<void> {
    try {
      const data = await this.getLocalDataByKey(ForageKeys.AMMSCAN_DATA);

      this.store.dispatch(BeltActions.setBeltsSuccess({ belts: data.belts ?? [] }));
      this.store.dispatch(CustomerActions.setCustomersSuccess({ customers: data.customers ?? [] }));
      this.store.dispatch(SurveyActions.setSurveysSuccess({ surveys: data.surveys ?? [] }));
      this.store.dispatch(CscActions.setCscsSuccess({ cscs: data.cscs ?? [] }));
    } catch (err) {
      console.log(err);
    }
  }

  public async initTranslationsFromForage(): Promise<void> {
    try {
      const data: TranslationData = await localforage.getItem(ForageKeys.TRANSLATION_DATA);
      if (!data) return;

      this.translateService.initTranslationsFromForage(data);
    } catch (err) {
      console.log(err);
    }
  }

  private async getLocalDataByKey(key): Promise<SynchronisationData> {
    try {
      let data: SynchronisationData = await localforage.getItem(key);
      if (!data) data = new SynchronisationData();
      return data;
    } catch (err) {
      console.error(err);
      return new SynchronisationData();
    }
  }

  private async saveStateToForage(state: State): Promise<void> {
    // TODO: only save to forage if the state we're storing has changed

    await this.saveDataToForage(ForageKeys.AMMSCAN_DATA, {
      surveys: state.survey.surveys,
      belts: state.belt.belts,
      customers: state.customer.customers,
      cscs: state.csc.cscs,
      syntheticMaterials: state.belt.syntheticMaterials,
      modularMaterials: state.belt.modularMaterials,
      modularTypes: state.belt.modularTypes,
      modularColours: state.belt.modularColours,
    });
  }

  private async saveTranslationDataToForage(data: TranslationData): Promise<void> {
    await this.saveDataToForage(ForageKeys.TRANSLATION_DATA, {
      translations: data.translations,
      languages: data.languages,
    });
  }

  private async saveDataToForage(
    key: string,
    data: SynchronisationData | TranslationData
  ): Promise<void> {
    try {
      await localforage.setItem(key, data);
    } catch (err) {
      console.log('error on save forage data. key, data:', key, data);
      console.error(err);
    }
  }

  public async clearForage(): Promise<void> {
    await localforage.clear();
  }
}
