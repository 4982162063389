import { __decorate, __extends } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, cordova } from '@awesome-cordova-plugins/core';
import * as i0 from "@angular/core";
export var DestinationType;
(function (DestinationType) {
  DestinationType[DestinationType["DATA_URL"] = 0] = "DATA_URL";
  DestinationType[DestinationType["FILE_URL"] = 1] = "FILE_URL";
  DestinationType[DestinationType["NATIVE_URI"] = 2] = "NATIVE_URI";
})(DestinationType || (DestinationType = {}));
export var EncodingType;
(function (EncodingType) {
  EncodingType[EncodingType["JPEG"] = 0] = "JPEG";
  EncodingType[EncodingType["PNG"] = 1] = "PNG";
})(EncodingType || (EncodingType = {}));
export var MediaType;
(function (MediaType) {
  MediaType[MediaType["PICTURE"] = 0] = "PICTURE";
  MediaType[MediaType["VIDEO"] = 1] = "VIDEO";
  MediaType[MediaType["ALLMEDIA"] = 2] = "ALLMEDIA";
})(MediaType || (MediaType = {}));
export var PictureSourceType;
(function (PictureSourceType) {
  PictureSourceType[PictureSourceType["PHOTOLIBRARY"] = 0] = "PHOTOLIBRARY";
  PictureSourceType[PictureSourceType["CAMERA"] = 1] = "CAMERA";
  PictureSourceType[PictureSourceType["SAVEDPHOTOALBUM"] = 2] = "SAVEDPHOTOALBUM";
})(PictureSourceType || (PictureSourceType = {}));
export var PopoverArrowDirection;
(function (PopoverArrowDirection) {
  PopoverArrowDirection[PopoverArrowDirection["ARROW_UP"] = 1] = "ARROW_UP";
  PopoverArrowDirection[PopoverArrowDirection["ARROW_DOWN"] = 2] = "ARROW_DOWN";
  PopoverArrowDirection[PopoverArrowDirection["ARROW_LEFT"] = 3] = "ARROW_LEFT";
  PopoverArrowDirection[PopoverArrowDirection["ARROW_RIGHT"] = 4] = "ARROW_RIGHT";
  PopoverArrowDirection[PopoverArrowDirection["ARROW_ANY"] = 5] = "ARROW_ANY";
})(PopoverArrowDirection || (PopoverArrowDirection = {}));
export var Direction;
(function (Direction) {
  Direction[Direction["BACK"] = 0] = "BACK";
  Direction[Direction["FRONT"] = 1] = "FRONT";
})(Direction || (Direction = {}));
var Camera = /** @class */function (_super) {
  __extends(Camera, _super);
  function Camera() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    /**
     * Constant for possible destination types
     */
    _this.DestinationType = {
      /** Return base64 encoded string. DATA_URL can be very memory intensive and cause app crashes or out of memory errors. Use FILE_URI or NATIVE_URI if possible */
      DATA_URL: 0,
      /** Return file uri (content://media/external/images/media/2 for Android) */
      FILE_URI: 1,
      /** Return native uri (eg. asset-library://... for iOS) */
      NATIVE_URI: 2
    };
    /**
     * Convenience constant
     */
    _this.EncodingType = {
      /** Return JPEG encoded image */
      JPEG: 0,
      /** Return PNG encoded image */
      PNG: 1
    };
    /**
     * Convenience constant
     */
    _this.MediaType = {
      /** Allow selection of still pictures only. DEFAULT. Will return format specified via DestinationType */
      PICTURE: 0,
      /** Allow selection of video only, ONLY RETURNS URL */
      VIDEO: 1,
      /** Allow selection from all media types */
      ALLMEDIA: 2
    };
    /**
     * Convenience constant
     */
    _this.PictureSourceType = {
      /** Choose image from picture library (same as PHOTOLIBRARY for Android) */
      PHOTOLIBRARY: 0,
      /** Take picture from camera */
      CAMERA: 1,
      /** Choose image from picture library (same as SAVEDPHOTOALBUM for Android) */
      SAVEDPHOTOALBUM: 2
    };
    /**
     * Convenience constant
     */
    _this.PopoverArrowDirection = {
      ARROW_UP: 1,
      ARROW_DOWN: 2,
      ARROW_LEFT: 4,
      ARROW_RIGHT: 8,
      ARROW_ANY: 15
    };
    /**
     * Convenience constant
     */
    _this.Direction = {
      /** Use the back-facing camera */
      BACK: 0,
      /** Use the front-facing camera */
      FRONT: 1
    };
    return _this;
  }
  Camera.prototype.getPicture = function (options) {
    return cordova(this, "getPicture", {
      "callbackOrder": "reverse"
    }, arguments);
  };
  Camera.prototype.cleanup = function () {
    return cordova(this, "cleanup", {
      "platforms": ["iOS"]
    }, arguments);
  };
  Camera.ɵfac = /* @__PURE__ */(() => {
    let ɵCamera_BaseFactory;
    return function Camera_Factory(t) {
      return (ɵCamera_BaseFactory || (ɵCamera_BaseFactory = i0.ɵɵgetInheritedFactory(Camera)))(t || Camera);
    };
  })();
  Camera.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: Camera,
    factory: Camera.ɵfac
  });
  Camera.pluginName = "Camera";
  Camera.plugin = "cordova-plugin-camera";
  Camera.pluginRef = "navigator.camera";
  Camera.repo = "https://github.com/apache/cordova-plugin-camera";
  Camera.platforms = ["Android", "Browser", "iOS", "Windows"];
  Camera = __decorate([], Camera);
  return Camera;
}(AwesomeCordovaNativePlugin);
export { Camera };
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Camera, [{
    type: Injectable
  }], null, {
    getPicture: [],
    cleanup: []
  });
})();
