import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Csc } from './../../models/csc.model';
import { environment } from './../../../environments/environment';

import { appInit } from '../core.actions';
import * as CscActions from '../csc/csc.actions';

@Injectable()
export class CscEffects {
  public constructor(
    private actions$: Actions<Action>,
    private httpClient: HttpClient
  ) {}

  public fetchCscs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appInit, CscActions.fetchCscs),
      switchMap(() => {
        return this.httpClient.get<Csc[]>(environment.baseUrl + '/csc').pipe(
          map((cscs) => {
            return CscActions.fetchCscsSuccess({ cscs });
          }),
          catchError((error) => {
            return of(CscActions.fetchCscsFailed({ error }));
          })
        );
      })
    )
  );
}
