<div class="d-flex">
  <app-sidebar></app-sidebar>
  <div class="w-100 min-h-100 overflow-auto pt-lg-0">
    <app-loading class="position-fixed top-0"></app-loading>
    <app-alert></app-alert>

    <div *ngIf="!isCscsStateValid($cscs | async) && online">
      <div class="container d-flex justify-content-center align-items-center min-h-100">
        <div class="text-center">
          <h3 class="text-muted">{{ t.translate('_NO_CSCS_FOUND') }}</h3>
          <p>{{ t.translate('_NO_CSCS_FOUND_DESCRIPTION') }}</p>

          <button
            class="btn btn-primary"
            (click)="onReloadPageClicked()">
            {{ t.translate('_RELOAD_PAGE') }}
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="(!isCscsStateValid($cscs | async) || !isUserStateValid($user | async)) && !online">
      <div class="container d-flex justify-content-center align-items-center min-h-100">
        <div class="text-center">
          <!-- <h3 class="text-muted">{{ t.translate("_NO_CSCS_FOUND") }}</h3>
          <p>{{ t.translate("_NO_CSCS_FOUND_DESCRIPTION") }}</p> -->

          <h3 class="text-muted">Offline Data Missing</h3>
          <p>
            Your user role or CSC information has not yet been stored for offline user.<br />
            Please go online and reload the page before you can access the application offline.
          </p>
        </div>
      </div>
    </div>

    <div *ngIf="isCscsStateValid($cscs | async) && isUserStateValid($user | async)">
      <router-outlet></router-outlet>
    </div>

    <div
      class="small text-white text-center bottom-0 end-0 d-flex justify-content-end p-2 user-select-none position-fixed">
      <div class="connection-status shadow flex-center overflow-hidden">
        <div
          class="offline-warning p-2 gap-1 small"
          [tooltip]="t.translate('_YOU_ARE_OFFLINE_FEATURES_UNAVAILABLE')"
          container="body"
          *ngIf="!online">
          <i class="material-symbols-outlined fw-100">public_off</i>
          <span class="pe-1">{{ t.translate('_DISCONNECTED') }}</span>
        </div>
        <div
          class="online-warning p-2 gap-1 small"
          [tooltip]="t.translate('_YOU_ARE_ONLINE')"
          container="body"
          *ngIf="online">
          <i class="material-symbols-outlined fw-100">public</i>
          <span class="pe-1">{{ t.translate('_CONNECTED') }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
