import { Injectable } from '@angular/core';

import { User } from '../auth/auth.service';

@Injectable()
export class StorageService {
  private static USER: string = 'USER';
  private static LANGUAGE_KEY: string = 'LANGUAGE_KEY';
  private static IS_AUTO_SYNC: string = 'IS_AUTO_SYNC';
  private static AMMSCAN_TOKEN: string = 'AMMSCAN_TOKEN';

  public getLanguageKey(): string {
    return localStorage.getItem(StorageService.LANGUAGE_KEY);
  }

  public setLanguageKey(languageKey: string): void {
    localStorage.setItem(StorageService.LANGUAGE_KEY, languageKey);
  }

  public getIsAutoSync(): boolean {
    return JSON.parse(localStorage.getItem(StorageService.IS_AUTO_SYNC));
  }

  public setIsAutoSync(isAutoSync: boolean): void {
    localStorage.setItem(StorageService.IS_AUTO_SYNC, isAutoSync.toString());
  }

  public getUser(): User {
    const userJson = localStorage.getItem(StorageService.USER);
    if (!userJson) return null;
    return JSON.parse(userJson) as User;
  }

  public setUser(user: User): void {
    localStorage.setItem(StorageService.USER, user ? JSON.stringify(user) : '');
  }

  public setMsalToken(token: string): void {
    localStorage.setItem(StorageService.AMMSCAN_TOKEN, token);
  }

  public getMsalToken(): string {
    return localStorage.getItem(StorageService.AMMSCAN_TOKEN);
  }

  public clear(): void {
    localStorage.clear();
  }
}
