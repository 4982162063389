/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SurveyState } from './survey.state';
import { selectAllCustomers } from '../customer';
import { selectAllBelts } from '../belt/belt.selector';

export const selectSurveys = createFeatureSelector<SurveyState>('survey');

export const selectAllSurveys = createSelector(
  selectSurveys,
  selectAllCustomers,
  selectAllBelts,
  (state: SurveyState, customers, belts) => {
    const filteredSurveys = state.surveys.filter((survey) => !survey.deleted);
    const mappedSurveys = filteredSurveys.map((survey) => {
      const customer = customers.find((customer) => customer.id == survey.customerId);
      const surveyBelts = belts.filter((belt) => belt.surveyId == survey.id);
      return {
        ...survey,
        customer,
        belts: surveyBelts,
      };
    });

    const surveys = mappedSurveys.filter((survey) => !!survey.customer);
    return { surveys, loadedAt: state.loadedAt };
  }
);

export const selectSurveysLoading = createSelector(
  selectSurveys,
  (state: SurveyState) => state.loading
);

export const selectSurveyById = (props: { id: string }) =>
  createSelector(selectAllSurveys, ({ surveys }) =>
    surveys.find((survey) => survey.id == props.id)
  );
