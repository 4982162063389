import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Image } from '../../../../app/shared/components/image-input/models/image';

import { AlertService } from '../../services/alert/alert.service';
import { TranslateService } from '../../../services/translate/translate.service';

@Component({
  selector: 'image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss'],
})
export class ImageInputComponent {
  @Input() public image: Image;
  @Output() public change: EventEmitter<Image> = new EventEmitter();

  @Input() public size: number = 325;
  @Input() public error = '';
  @Input() public maxKb: number;
  @Input() public label: string;

  public constructor(
    private sanitizer: DomSanitizer,
    private alertService: AlertService,
    public t: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  public onFileSelected(event: {
    target: { files: File[]; value: string };
    stopPropagation: () => void;
  }): void {
    if (!event.target.files || !event.target.files.length) {
      return;
    }
    const file = event.target.files[0];

    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      this.alertService.alertError('Unsupported file type. Please select a JPEG or PNG image.');
      return;
    }

    if (this.maxKb && file.size / 1000 > this.maxKb) {
      this.alertService.alertError(`This image is too large. Max size is ${this.maxKb}KB.`);
      return;
    }

    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target?.result) {
        const display = this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result as string);

        this.image = {
          file: file,
          displayImage: display,
          isNew: true,
        };

        this.change.emit(this.image);
        this.cdr.detectChanges();
      } else {
        console.error('FileReader result is null or undefined.');
      }
    };

    reader.onerror = (error) => {
      console.error('Error reading the file:', error);
      this.alertService.alertError('Error reading the file.');
    };

    reader.readAsDataURL(file);

    event.stopPropagation();
  }

  public getIconSize(): number {
    return Math.max(30, Math.min(this.size, 72)) - 10;
  }

  public clear(): void {
    this.image = new Image();
    this.change.emit(this.image);
  }

  public async download(): Promise<void> {
    const downloadLink = document.createElement('a');

    try {
      let downloadUrl: string;
      if (this.image.file) {
        downloadUrl = window.URL.createObjectURL(this.image.file);
      } else {
        const response = await fetch(this.image.displayImage.toString(), {
          headers: new Headers({
            Origin: location.origin,
          }),
          mode: 'cors',
        });
        const imageBlob = await response.blob();
        downloadUrl = window.URL.createObjectURL(imageBlob);
      }

      downloadLink.href = downloadUrl;
      downloadLink.target = '_blank';
      downloadLink.setAttribute('download', 'Image');

      document.body.appendChild(downloadLink);
      downloadLink.click();
    } catch (error) {
      this.alertService.alertError('Error downloading the image.');
    } finally {
      document.body.removeChild(downloadLink);
    }
  }
}
