<div
  class="ms-2 filter-btn text-white position-relative rounded"
  (click)="onFilterButtonClick()"
  [tooltip]="t.translate('_FILTER')">
  <i class="fa fa-filter"></i>
  <div
    class="filter-counter rounded"
    *ngIf="filterCount > 0">
    {{ filterCount }}
  </div>
</div>
