import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { environment } from '../../../environments/environment';

import { UtilService } from '../../utils/util.service';
import { AuthService } from '../../services/auth/auth.service';

import { Belt } from '../../models/belt.model';
import { Survey } from '../../models/survey.model';
import { Customer } from '../../models/customer.model';

import * as BeltActions from '../../state/belt/belt.actions';
import * as SurveyActions from '../../state/survey/survey.actions';
import * as CustomerActions from '../../state/customer/customer.actions';

export interface VerifiedIds {
  customerIds: string[];
  surveyIds: string[];
  beltIds: string[];
}

@Injectable()
export class FetchService {
  public constructor(
    private store: Store,
    private httpClient: HttpClient,
    private authService: AuthService,
    private utils: UtilService
  ) {}

  public async fetchServerDataAndUpdateState(): Promise<void> {
    const user = this.authService.getUser();

    const surveys = await this.fetchSurveys();
    const belts = await this.fetchBelts();
    const customers = await this.fetchCustomers();

    const userConfirm = this.authService.getUser();
    if (!userConfirm || user?.id != userConfirm.id) return;

    this.store.dispatch(BeltActions.fetchBeltsSuccess({ belts }));
    this.store.dispatch(CustomerActions.fetchCustomersSuccess({ customers }));
    this.store.dispatch(SurveyActions.fetchSurveysSuccess({ surveys }));
  }

  public async fetchVerifiedIds(): Promise<VerifiedIds> {
    const customerIds = await this.fetchCustomerIds();
    const surveyIds = await this.fetchSurveyIds();
    const beltIds = await this.fetchBeltIds();

    return {
      customerIds,
      surveyIds,
      beltIds,
    };
  }

  /*
    TODO: add pagination to the fetching
  */
  private async fetchSurveys(): Promise<Survey[]> {
    const obs = this.httpClient.get<Survey[]>(`${environment.baseUrl}/surveys`);
    return this.utils.observableToPromise(obs);
  }

  private async fetchCustomers(): Promise<Customer[]> {
    const obs = this.httpClient.get<Customer[]>(`${environment.baseUrl}/customers`);
    return this.utils.observableToPromise(obs);
  }

  private async fetchBelts(): Promise<Belt[]> {
    const obs = this.httpClient.get<Belt[]>(`${environment.baseUrl}/belts`);
    return this.utils.observableToPromise(obs);
  }

  private async fetchSurveyIds(): Promise<string[]> {
    const obs = this.httpClient.get<string[]>(`${environment.baseUrl}/surveys/ids`);
    return this.utils.observableToPromise(obs);
  }

  private async fetchCustomerIds(): Promise<string[]> {
    const obs = this.httpClient.get<string[]>(`${environment.baseUrl}/customers/ids`);
    return this.utils.observableToPromise(obs);
  }

  private async fetchBeltIds(): Promise<string[]> {
    const obs = this.httpClient.get<string[]>(`${environment.baseUrl}/belts/ids`);
    return this.utils.observableToPromise(obs);
  }
}
