import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';

export class AppFormGroup extends FormGroup {
  public submitted = false;

  public constructor(
    controls: { [key: string]: AbstractControl },
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(controls, validatorOrOpts, asyncValidator);
  }

  public markAsSubmitted(): void {
    this.submitted = true;
  }

  public markAsNotSubmitted(): void {
    this.submitted = false;
  }
}
