import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setTheme } from 'ngx-bootstrap/utils';

import { environment } from './../environments/environment';
import { appInit } from './state/core.actions';

import { SyncService } from './services/sync/sync.service';
import { ForageService } from './services/forage/forage.service';
import { LoadingService } from './shared/services/loading/loading.service';
import { MsalTokenService } from './services/msal-token/msal-token.service';
import { OnlineService } from './services/online-service/online.service';
import { TranslateService } from './services/translate/translate.service';
import { AuthService } from './services/auth/auth.service';
import { StorageService } from './services/storage/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public loading: boolean = false;
  public startupError: boolean = false;
  public initializingProgressMessage: string = '';

  public online: boolean;

  public constructor(
    private store: Store,
    private router: Router,
    private t: TranslateService,
    private authService: AuthService,
    private syncService: SyncService,
    private onlineService: OnlineService,
    private forageService: ForageService,
    private storageService: StorageService,
    private loadingService: LoadingService,
    private msalTokenService: MsalTokenService
  ) {
    setTheme('bs5');
    this.addClarityTrackingCodeToHead();
  }

  public async ngOnInit(): Promise<void> {
    if (window?.location.href.includes('logout')) return;

    this.loadingService.show();
    this.loading = true;

    try {
      this.initializingProgressMessage = 'Initializing Local Database...';
      await this.forageService.init();
      this.initializingProgressMessage = 'Checking Online Status...';
      this.online = await this.onlineService.canConnectToApi();
      if (!this.online) {
        await this.offlineInit();
      } else {
        await this.onlineInit();
      }

      if (MsalTokenService.isMobileApp()) {
        const user = this.authService.getUser();
        if (user) this.router.navigate(['surveys']);
      }
    } catch (error) {
      this.startupError = true;
      // TODO: show user a screen to opt to logout
      // TODO: show errors
      this.msalTokenService.logout();
      console.error('Error during MSAL initialization:', error);
    }

    this.loading = false;
    this.loadingService.hide();
  }

  private async offlineInit(): Promise<void> {
    this.initializingProgressMessage = 'Initializing Offline...';
    const token = this.storageService.getMsalToken();
    if (token) {
      this.syncService.init();
    }
  }

  private async onlineInit(): Promise<void> {
    this.initializingProgressMessage = 'Initializing Online...';
    await this.msalTokenService.init();
    this.initializingProgressMessage = 'Authenticating...';
    await this.msalTokenService.authenticate();
    this.store.dispatch(appInit());
    this.syncService.init();
    this.initializingProgressMessage = 'Fetching Translations...';
    await this.t.init();
  }

  private addClarityTrackingCodeToHead(): void {
    const head = document.getElementsByTagName('head')[0];
    const isProduction = environment.production;

    if (isProduction) {
      const script = document.createElement('script');
      script.innerHTML = `
      (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "j1wofi2ghz");
      `;
      head.insertBefore(script, head.lastChild);
    }
  }

  public onReloadPageClicked(): void {
    window.location.reload();
  }
}
