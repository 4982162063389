import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';
import { distinctUntilChanged } from 'rxjs/operators';

import { AlertService } from './../../../shared/services/alert/alert.service';
import { AuthService } from './../../../services/auth/auth.service';
import {
  Breadcrumb,
  BreadcrumbService,
} from './../../../shared/services/breadcrumb/breadcrumb.service';
import { LoadingService } from './../../../shared/services/loading/loading.service';
import { ModalService } from '../../../services/modal/modal.service';
import { SurveyService } from './../../../services/survey/survey.service';
import { TranslateService } from '../../../services/translate/translate.service';
import { UtilService } from './../../../utils/util.service';
import { SyncService } from './../../../services/sync/sync.service';

import { Belt } from '../../../models/belt.model';
import { Survey } from './../../../models/survey.model';

import { SortableHeaderDirective } from './../../../shared/directives/sortable.directive';

import * as BeltActions from '../../../state/belt/belt.actions';
import * as SurveyActions from '../../../state/survey/survey.actions';
import * as SurveySelectors from '../../../state/survey/survey.selector';

@Component({
  selector: 'app-belt-list',
  templateUrl: './belt-list.component.html',
  styleUrls: ['./belt-list.component.scss'],
})
export class BeltListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(SortableHeaderDirective) public headers: QueryList<SortableHeaderDirective>;
  @ViewChild('copyModal') public copyModal;
  @ViewChild('filterModal') public filterModal;
  @ViewChild('fileUpload') public fileUploadElement;

  private sortFields = {
    page: 1,
    pageSize: 20,
    sortColumn: 'updated',
    sortDirection: 'desc',
  };

  private appliedFilters = new BeltFilter();
  private selectedFilters = new BeltFilter();

  public searchText: string = '';
  public newBeltReference: string = '';

  public survey: Survey;
  public currentBelt: Belt;
  private belts: Belt[] = [];

  public beltTypes: string[] = [];
  public filteredBelts: Belt[] = [];
  public productionLines: string[] = [];
  private subscriptions: Subscription[] = [];

  public maxDate = new Date();

  private survey$: Observable<Survey>;

  private bsModalRef: BsModalRef;

  public isScreenSmall: boolean;
  private resizeListener;

  public constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private breadcrumbService: BreadcrumbService,
    private loadingService: LoadingService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private surveyService: SurveyService,
    private utils: UtilService,
    private syncService: SyncService,
    public t: TranslateService
  ) {}

  public ngAfterViewInit(): void {
    this.checkScreenSize();
    this.resizeListener = () => this.checkScreenSize();
    window.addEventListener('resize', this.resizeListener, true);
    this.cdr.detectChanges();
  }

  public ngOnInit(): void {
    const routeSub = this.route.params.subscribe((params) => {
      this.fetchData(params['survey']);
    });
    this.subscriptions.push(routeSub);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      if (sub) sub.unsubscribe();
    });
  }

  private checkScreenSize(): void {
    this.setIsSmallScreen(document.body.offsetWidth < 992);
  }

  private setIsSmallScreen(isSmallScreen: boolean): void {
    if (this.isScreenSmall != isSmallScreen) {
      this.isScreenSmall = isSmallScreen;
    }
  }

  private fetchData(surveyId: string): void {
    this.survey$ = this.store.select(SurveySelectors.selectSurveyById({ id: surveyId }));

    const surveySub = this.survey$
      .pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)))
      .subscribe((survey) => {
        if (!survey) return;
        this.survey = survey;
        this.createAndAddBreadcrumb(survey);

        const belts = this.reassignBeltValues(survey.belts);
        this.belts = [...belts];

        this.beltTypes = [
          ...new Set(this.belts.filter((belt) => belt.synOrMod).map((belt) => belt.synOrMod)),
        ];
        const productionLines = [...new Set(this.belts.map((belt) => belt.productionLine || '-'))];
        this.productionLines = productionLines.sort((a, b) => this.utils.compare(a, b));

        this.filterAndSortBelts();
      });

    this.subscriptions.push(surveySub);
  }

  private createAndAddBreadcrumb(survey): void {
    const crumb = new Breadcrumb(survey.customer?.customer_name, ['/', 'surveys', survey.id]);
    this.breadcrumbService.pushBreadcrumb(crumb);
  }

  private reassignBeltValues(belts: Belt[]): Belt[] {
    belts = belts.filter((belt) => !belt.deleted);
    belts = belts.map((belt) => ({
      ...belt,
      productionLine: belt.productionLine || this.t.translate('_NONE'),
    }));
    return belts;
  }

  public onItemClick(belt: Belt): void {
    this.router.navigate(['/surveys', this.survey.id, 'belts', belt.id, 'edit']);
  }

  public filterAndSortBelts(): void {
    const belts = this.filterBelts();
    this.sortBelts(belts);
  }

  public getFilterCount(): number {
    let count = 0;
    count += this.appliedFilters.beltTypes.length;
    count += this.appliedFilters.productionLines.length;
    count += this.appliedFilters.dates.length ? 1 : 0;
    return count;
  }

  public applyFilters(): void {
    this.hideModal();
    this.appliedFilters = Object.assign({}, this.selectedFilters);
    this.filterAndSortBelts();
  }

  public clearFilters(): void {
    this.hideModal();
    this.appliedFilters = new BeltFilter();
    this.selectedFilters = new BeltFilter();
    this.filterAndSortBelts();
  }

  public clearDates(): void {
    this.selectedFilters.dates = [];
  }

  private showModal(modal): void {
    this.bsModalRef = this.modalService.show(modal);
  }

  public hideModal(): void {
    this.bsModalRef.hide();
  }

  public showFilterModal(): void {
    this.selectedFilters = Object.assign({}, this.appliedFilters);
    this.showModal(this.filterModal);
  }

  public async onCopyBelt(belt: Belt): Promise<void> {
    this.currentBelt = belt;
    this.newBeltReference = belt.productReference + '';
    this.showModal(this.copyModal);
  }

  public async onRemoveBelt(belt: Belt): Promise<void> {
    this.modalService.showDeleteModal({
      title: `${this.t.translate('_REMOVE_BELT_CONFIG')}: ${belt.productReference}`,
      message: `${this.t.translate('_ARE_YOU_SURE?')}`,
      delete: () => this.removeBelt(belt),
    });
  }

  private async removeBelt(beltInput: Belt): Promise<void> {
    const belt: Belt = Object.assign({}, beltInput);
    belt.updated = new Date();
    belt.deleted = true;
    belt.dirty = true;

    this.store.dispatch(BeltActions.removeBeltSuccess({ belt }));
    this.alertService.alertSuccess(this.t.translate('_BELT_CONFIG_REMOVED_SUCCESSFULLY'));
    await this.syncService.trySyncUpToApi();
  }

  public async onRemoveSurvey(survey: Survey): Promise<void> {
    this.modalService.showDeleteModal({
      title: `${this.t.translate('_REMOVE_SURVEY')}: ${survey.customer.customer_name}`,
      message: `${this.t.translate('_ARE_YOU_SURE?')}`,
      delete: () => this.removeSurvey(survey),
    });
  }

  public async removeSurvey(surveyInput: Survey): Promise<void> {
    const survey: Survey = Object.assign({}, surveyInput);
    survey.updated = new Date();
    survey.deleted = true;
    survey.dirty = true;

    this.store.dispatch(SurveyActions.removeSurveySuccess({ survey }));
    this.breadcrumbService.popBreadcrumb();
    this.alertService.alertSuccess('Survey removed successfully');
    await this.syncService.trySyncUpToApi();
  }

  public async copyBelt(beltInput: Belt): Promise<void> {
    this.loadingService.show();
    try {
      const belt = Object.assign({}, beltInput);
      belt.id = uuidv4();
      belt.created = new Date();
      belt.updated = new Date();
      belt.attachmentUrl = '';
      belt.dirty = true;
      belt.image = null;
      belt.productReference = this.newBeltReference || '[COPY] ' + belt.productReference;
      belt.surveyor = { email: this.authService.getUser().email };

      this.store.dispatch(BeltActions.addBeltSuccess({ belt }));
      this.alertService.alertSuccess(this.t.translate('_BELT_CONFIG_COPIED_SUCCESSFULLY'));

      this.syncService.trySyncUpToApi();

      this.hideModal();
      this.currentBelt = null;
      this.newBeltReference = '';
    } catch (err) {
      this.alertService.handleHttpError(err);
    }
    this.loadingService.hide();
  }

  public downloadSurveyCsv(): void {
    if (this.belts.length < 1) {
      this.alertService.alertWarning('There are no belts to export');
      return;
    }
    const csvString = this.surveyService.getSurveyCsvString(this.survey);
    this.utils.performCsvDownload(
      csvString,
      `survey-${this.survey.customer.customer_name}-${new Date().getTime()}.csv`
    );
  }

  private sortBelts(unsortedBelts: Belt[]): void {
    const { sortColumn, sortDirection } = this.sortFields;
    let belts = unsortedBelts;

    if (this.headers) {
      this.filteredBelts = unsortedBelts;

      this.headers.forEach((header) => {
        if (header.sortable != sortColumn) {
          header.direction = '';
        }
      });
    }

    if (sortColumn === 'updated') {
      belts = belts.sort((a, b) => {
        const dateA = new Date(a.updated);
        const dateB = new Date(b.updated);

        if (sortDirection === 'asc') {
          return dateA.getTime() - dateB.getTime();
        } else {
          return dateB.getTime() - dateA.getTime();
        }
      });
    } else if (sortColumn && sortDirection) {
      belts = this.utils.sortAlphaNumeric(belts, sortColumn, sortDirection === 'desc');
    }

    this.filteredBelts = belts;
  }

  private doesBeltMatchSearch(belt: Belt): boolean {
    const search = this.searchText.toLowerCase();
    const totalString =
      belt.id +
      ' ' +
      belt.productReference +
      ' ' +
      belt.material +
      ' ' +
      belt.beltMaterial +
      ' ' +
      belt.productionLine +
      ' ' +
      belt.synOrMod +
      ' ';
    return totalString.toLowerCase().includes(search);
  }

  private filterBelts(): Belt[] {
    let filteredBelts = this.belts.filter((belt) => belt);

    if (this.appliedFilters.beltTypes.length) {
      filteredBelts = filteredBelts.filter((belt) => {
        return this.appliedFilters.beltTypes.includes(belt.synOrMod);
      });
    }

    if (this.appliedFilters.dates.length) {
      filteredBelts = filteredBelts.filter((belt) => {
        const start = this.utils.getDateAtMidnight(this.appliedFilters.dates[0]);
        const end = this.utils.getDateAtMidnight(
          this.appliedFilters.dates[this.appliedFilters.dates.length - 1]
        );
        end.setDate(end.getDate() + 1);

        const beltDate = new Date(belt.updated).getTime();

        return beltDate >= start.getTime() && beltDate < end.getTime();
      });
    }

    if (this.appliedFilters.productionLines.length) {
      filteredBelts = filteredBelts.filter((belt) => {
        return this.appliedFilters.productionLines.includes(belt.productionLine);
      });
    }

    return filteredBelts.filter((x) => this.doesBeltMatchSearch(x));
  }
}

export class BeltFilter {
  public beltTypes: unknown[] = [];
  public dates: Date[] = [];
  public productionLines: unknown[] = [];
}
