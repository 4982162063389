/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

const bootstrap = () => {
  platformBrowserDynamic().bootstrapModule(AppModule);
};

if (typeof window['cordova'] != 'undefined') {
  document.addEventListener('deviceready', () => bootstrap(), false);
} else {
  bootstrap();
}
