import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { RoleEnum } from 'src/app/utils/util.service';
import { AmmscanApiService } from '../api/ammscanApi.service';
import { StorageService } from '../storage/storage.service';

import { loginSuccess } from './../../state/user/user.actions';

export interface User {
  id: string;
  email: string;
  role?: RoleEnum;
}

@Injectable()
export class AuthService {
  private user: User = null;

  public constructor(
    private store: Store,
    private storageService: StorageService,
    private ammscanApiService: AmmscanApiService
  ) {
    this.user = this.storageService.getUser();
  }

  public getUser(): User {
    return this.user;
  }

  public async onMsalAuthChange(userData: { id: string; email: string }): Promise<void> {
    if (!userData) {
      this.user = null;
      this.storageService.setUser(null);
      return;
    }

    const user = this.storageService.getUser();
    if (!user || user.id !== userData.id) {
      const role = await this.fetchUserRole();

      this.user = {
        id: userData.id,
        email: userData.email,
        role,
      };

      this.storageService.setUser(this.user);
    }

    this.store.dispatch(loginSuccess({ user: this.user }));
  }

  private async fetchUserRole(): Promise<RoleEnum> {
    try {
      return this.ammscanApiService.get(`/users/role`);
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}
