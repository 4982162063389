import { createAction, props } from '@ngrx/store';

import { User } from 'src/app/services/auth/auth.service';

export const setError = createAction('[User] Set Error', props<{ error: Event }>());

export const loginSuccess = createAction('[User] Login Success', props<{ user: User }>());
export const loginFailed = createAction('[User] Login Failed', props<{ error: Event }>());

export const logout = createAction('[User] Logout Initiated');
export const logoutSuccess = createAction('[User] Logout Success');

export const subscribe = createAction(
  '[User] Subscribe to Newsletter',
  props<{ subscribed: boolean }>()
);
export const subscribeSuccess = createAction(
  '[User] Subscribe to Newsletter Success',
  props<{ subscribed: boolean }>()
);
export const subscribeFailed = createAction(
  '[User] Subscribe to Newsletter Failed',
  props<{ error: unknown }>()
);
