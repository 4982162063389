import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';

import { StorageService } from '../storage/storage.service';
import { AmmscanApiService } from '../api/ammscanApi.service';

import { Language } from '../../models/language';
import { Translation } from '../../models/translation';

export enum TranslateContext {
  common = 'common',
}

export class TranslationData {
  public translations: Translation[] = [];
  public languages: Language[] = [];
}

const LanguageFlags = {
  en: 'gb',
  nl: 'nl',
  pl: 'pl',
  cz: 'cz',
  ger: 'de',
  fr: 'fr',
  fn: 'fi',
  SWE: 'se',
  TH: 'th',
  ESP: 'es',
};

@Injectable({ providedIn: 'root' })
export class TranslateService {
  private contextsFetched: TranslateContext[] = [];
  private translationMap: { [key: string]: Translation } = {};
  private language: Language;
  private data: TranslationData = {
    translations: [],
    languages: [],
  };

  private subscribers: Subscriber<TranslationData>[] = [];
  private observable: Observable<TranslationData>;

  public constructor(
    private storageService: StorageService,
    private ammscanApiService: AmmscanApiService
  ) {
    this.observable = new Observable<TranslationData>((sub) => {
      this.subscribers.push(sub);
      sub.next(this.data);
    });
  }

  public returnTranslations(): TranslationData {
    return this.data;
  }

  private onLanguageOrTranslationsChanged(): void {
    this.subscribers = this.subscribers.filter((x) => !x.closed);

    for (const subscriber of this.subscribers) {
      subscriber.next(this.deepCopy(this.data));
    }
  }

  public subscribeToTranslationData(callback: (data: TranslationData) => void): Subscription {
    return this.observable.subscribe(callback);
  }

  public initTranslationsFromForage(data: TranslationData): void {
    this.data = data;
    this.onTranslationDataInitialised();
  }

  public async init(): Promise<void> {
    if (this.data.languages.length) {
      this._init();
    } else {
      await this._init();
    }
  }

  private async _init(): Promise<void> {
    try {
      const languageKey = this.storageService.getLanguageKey();
      if (!languageKey) {
        const cscLanguage = await this.fetchCscLanguageByUser();
        this.storageService.setLanguageKey(cscLanguage.key);
      }

      this.data.languages = await this.fetchLanguages();
      this.data.translations = await this.fetchTranslations(TranslateContext.common);
      this.onTranslationDataInitialised();
    } catch (err) {
      console.log(err);
    }
  }

  private onTranslationDataInitialised(): void {
    const languageKey = this.getLanguageKey();
    this.language = this.data.languages.find((x) => x.key === languageKey);

    this.updateTranslationMap();
    this.onLanguageOrTranslationsChanged();
  }

  private updateTranslationMap(): void {
    for (const translation of this.data.translations) {
      this.translationMap[translation.key + translation.language_id] = translation;
    }
  }

  private async fetchLanguages(): Promise<Language[]> {
    const languages: Language[] = await this.ammscanApiService.get('/translate/language/');
    return languages.map((l) => ({
      ...l,
      country_flag: LanguageFlags[l.key],
    }));
  }

  private async fetchTranslations(context: TranslateContext): Promise<Translation[]> {
    if (this.contextsFetched.includes(context)) return this.data.translations;

    const endpoint = '/translate/translations/context/' + context;
    const translations = await this.ammscanApiService.get(endpoint);

    this.contextsFetched.push(context);
    return translations as Translation[];
  }

  public translate(key: string): string {
    if (!this.language) return key;

    const translation: Translation = this.translationMap[key + this.language.id];
    if (translation?.translation) return translation.translation;

    return key;
  }

  private fetchCscLanguageByUser(): Promise<Language> {
    return this.ammscanApiService.get('/translate/csc-language/');
  }

  public fetchLanguageTranslations(language: Language): Promise<Translation[]> {
    return this.ammscanApiService.get('/translate/translations/language/' + language.id);
  }

  public async saveTranslations(translations: Translation[]): Promise<Translation[]> {
    translations = await this.ammscanApiService.post('/translate', translations);

    const newTranslationMap: { [key: string]: Translation } = {};
    for (const t of translations) newTranslationMap[t.id] = t;
    for (const t of this.data.translations) {
      if (!newTranslationMap[t.id]) translations.push(t);
    }
    this.data.translations = translations;

    this.updateTranslationMap();
    this.onLanguageOrTranslationsChanged();

    return translations;
  }

  public setLanguage(language: Language): void {
    this.storageService.setLanguageKey(language.key);
    this.language = this.deepCopy(language);
    this.onLanguageOrTranslationsChanged();
  }

  public getLanguageKey(): string {
    return this.storageService.getLanguageKey() || 'en';
  }

  private deepCopy<T>(value: T): T {
    return JSON.parse(JSON.stringify(value));
  }

  public formatTranslationKey(inputValue: string): string {
    return '_' + inputValue.toUpperCase().replaceAll(' ', '_');
  }
}
