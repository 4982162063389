<div class="bg-white bdr rounded table-responsive">
  <table
    class="table"
    *ngIf="rows.length">
    <thead>
      <tr class="small position-sticky z-1 top-0">
        <ng-container *ngFor="let row of rows[0] | keyvalue: returnZero">
          <th
            class="cursor-pointer user-select-none p-3 fw-500 text-capitalize border-bottom"
            (click)="onColumnClick(row.key)"
            *ngIf="!['ID', 'ACTIONS'].includes(row.key.toUpperCase())">
            <div
              class="d-flex align-items-center"
              *ngIf="row.key.toUpperCase() != 'CHECKBOX'">
              <span>{{ t.translate(getTableTranslationKeys(row.key.toUpperCase())) }}</span>
              <i
                class="sort-icon ms-1 material-symbols-outlined"
                [class.text-warning]="row.key == sortColumn"
                [class.invert]="row.key == sortColumn && sortDirection == 'ASC'">
                sort
              </i>
            </div>
            <div *ngIf="row.key.toUpperCase() == 'CHECKBOX'">&nbsp;</div>
          </th>
        </ng-container>
        <th
          *ngIf="tableHasActions"
          class="border-bottom">
          &nbsp;
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="small cursor-pointer"
        *ngFor="let row of rows">
        <ng-container *ngFor="let col of row | keyvalue: returnZero">
          <td
            class="p-2"
            [class]="getStyle(col)"
            [class.overflow-ellipsis]="col.key != 'ACTIONS'"
            (click)="onRowClick(row, $event)"
            *ngIf="
              !(col.key.toUpperCase() == 'ID') &&
              col.key != 'ACTIONS' &&
              col.key.toUpperCase() != 'IMAGE' &&
              col.key.toUpperCase() != 'BELT TYPE' &&
              col.key.toUpperCase() != 'SYNC'
            ">
            {{
              col.key.toUpperCase() == 'DATE' || col.key.toUpperCase() == 'UPDATED'
                ? (col.value | date: 'dd MMM YYYY, hh:mm a')
                : col.value
            }}
          </td>
          <td
            class="p-2"
            [class]="getStyle(col)"
            [class.overflow-ellipsis]="col.key != 'ACTIONS'"
            (click)="onRowClick(row, $event)"
            *ngIf="col.key.toUpperCase() == 'SYNC'">
            <i class="material-symbols-outlined text-success d-block">
              {{ col.value }}
            </i>
          </td>
          <td
            class="p-2"
            [class]="getStyle(col)"
            [class.overflow-ellipsis]="col.key != 'ACTIONS'"
            (click)="onRowClick(row, $event)"
            *ngIf="col.key.toUpperCase() == 'BELT TYPE'">
            <div
              class="px-3 mt-1 text-white text-capitalize text-center rounded"
              [class]="col.value?.toLowerCase()">
              {{ col.value }}
            </div>
          </td>
          <td
            class="p-2"
            [class]="getStyle(col)"
            [class.overflow-ellipsis]="col.key != 'ACTIONS'"
            (click)="onRowClick(row, $event)"
            *ngIf="col.key.toUpperCase() == 'IMAGE'">
            <div
              *ngIf="col.value != 'no image'"
              class="image-container rounded overflow-hidden position-relative">
              <img
                [src]="col.value"
                alt="" />
            </div>
            <div
              *ngIf="col.value == 'no image'"
              class="mage-container rounded overflow-hidden position-relative no-image text-primary">
              <i class="material-symbols-outlined no-image w-100 text-primary flex-center">
                question_mark
              </i>
            </div>
          </td>
        </ng-container>
        <td
          class="p-3 position-relative text-end"
          *ngIf="row.ACTIONS">
          <app-meta-menu
            icon="symbol"
            [menuItems]="row.ACTIONS">
          </app-meta-menu>
        </td>
      </tr>
    </tbody>
  </table>

  <div
    *ngIf="loading"
    class="d-flex justify-content-center p-3">
    <div class="spinner-border text-primary"></div>
  </div>

  <div
    *ngIf="!rows.length && !loading"
    class="text-center p-2">
    {{ t.translate('_NOTHING_FOUND') }}
  </div>
</div>
