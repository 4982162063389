import { Action, createReducer, on } from '@ngrx/store';

import { CscState, initialState } from './csc.state';
import { StateUtils } from '../state-utils';

import * as CscActions from './csc.actions';
import * as UserActions from '../user/user.actions';

const cscReducer = createReducer(
  initialState,

  on(CscActions.fetchCscs, (state) => ({
    ...state,
    loading: true,
  })),
  on(CscActions.fetchCscsSuccess, (state, { cscs }) => {
    return {
      ...state,
      cscs: StateUtils.combineStateArr(state.cscs, cscs),
      loading: false,
      loadedAt: new Date(),
    };
  }),
  on(CscActions.setCscsSuccess, (state, { cscs }) => {
    return {
      ...state,
      cscs,
      loading: false,
      loadedAt: cscs.length ? new Date() : null,
    };
  }),
  on(CscActions.fetchCscsFailed, (state) => ({
    ...state,
    loading: false,
    loadedAt: new Date(),
  })),

  on(UserActions.logoutSuccess, () => {
    return initialState;
  })
);

export function reducer(state: CscState | undefined, action: Action): CscState {
  return cscReducer(state, action);
}
