export const environment = {
  production: false,
  staging: true,
  baseUrl: 'https://ammcare-survey-api-esfchb.ammcare-acc.ccdns.co/api',
  ammcareBaseUrl: 'https://optiscan-api-nbjfxb.ammcare-acc.ccdns.co/api',
  storageApiBaseUrl: 'https://storage-api-evdmfb.ammcare-acc.ccdns.co',
  cBeltApi: 'https://func-indt-cblt-masterdata-d.azurewebsites.net/api',
  code: 'sPulSSxRStw20UhGDykBS0STX1c6hhoCuqp8Ak4qvpClAzFuWdnHYA==',
  cBeltClientId: '524f2546-dd01-472d-baf4-2a898e8dd98e',
  ammscanClientId: '6a1864c5-1ee0-464f-87f9-06395d863762',
  ammscanApiScope:
    'https://connectedbelt.onmicrosoft.com/04862f4e-e3ce-419b-a087-8c2e245b74b7/default',
  loginRedirectUri: 'https://ammscan-staging.ammeraalbeltech.com/surveys',
  logoutRedirectUri: 'https://ammscan-staging.ammeraalbeltech.com/logout',
  cBeltRedirectUri: 'msauth://com.ammega.connectedbelt.staging/VzSiQcXRmi2kyjzcA%2BmYLEtbGVs%3D',
  version: '2025-02-10',
};
