import { Action, createReducer, on } from '@ngrx/store';

import * as UserActions from './user.actions';
import { initialState, UserState } from './user.state';

const userReducer = createReducer(
  initialState,

  on(UserActions.setError, (state, { error }) => ({
    ...state,
    error,
  })),

  on(UserActions.loginSuccess, (state, { user }) => ({
    ...state,
    loading: false,
    loadedAt: new Date(),
    currentUser: user,
  })),

  on(UserActions.loginFailed, (state, { error }) => ({
    ...state,
    loading: false,
    currentUser: null,
    error,
  })),

  on(UserActions.logout, (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(UserActions.logoutSuccess, (state) => {
    return {
      ...state,
      currentUser: null,
      loading: false,
    };
  })
);

export function reducer(state: UserState | undefined, action: Action): UserState {
  return userReducer(state, action);
}
