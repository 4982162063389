<div class="modal-header align-items-center bg-danger">
  <h4 class="modal-title text-white">{{ options.title }}</h4>
  <div
    class="close pointer text-white fs-3"
    aria-label="Close"
    (click)="close()">
    <span aria-hidden="true">&times;</span>
  </div>
</div>

<div class="modal-body">
  {{ options.message }}
</div>

<div class="modal-footer">
  <button
    class="btn btn-danger"
    data-testid="confirm-delete-btn"
    (click)="onDelete()">
    {{ t.translate('_YES') }}
  </button>
  <button
    class="btn btn-outline-secondary"
    data-testid="cancel-delete-btn"
    (click)="close()">
    {{ t.translate('_NO') }}
  </button>
</div>
