<div class="alert-holder">
  <div
    *ngFor="let alert of alerts"
    [@slideInFadeOut]>
    <div
      class="alert border-{{ getAlertClass(alert) }}"
      [ngClass]="[getAlertClass(alert)]"
      [attr.data-testid]="getAlertClass(alert)"
      (click)="removeAlert(alert)">
      <div data-testid="alert-message">{{ alert.text }}</div>
      <div class="pointer close-button close">
        <span aria-hidden="true">&times;</span>
      </div>
    </div>
  </div>
</div>
