import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SortColumn } from './../shared/directives/sortable.directive';

@Injectable()
export class UtilService {
  public compare(a: number | string, b: number | string, isAsc: boolean = true): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public sort<T>(array: T[], column: SortColumn, isAsc: boolean = true): T[] {
    if (array.length <= 1) return array;

    if (column == '') {
      return array;
    } else {
      return [...array].sort((a, b) => {
        if (!a[column] && !b[column]) {
          return 0;
        }
        if (!a[column] && isAsc) {
          return 1;
        }
        if (!a[column] && !isAsc) {
          return -1;
        }
        if (!b[column] && isAsc) {
          return -1;
        }
        if (!b[column] && !isAsc) {
          return 1;
        }
        return this.compare(a[column], b[column], isAsc);
      });
    }
  }

  public sortAlphaNumeric<T>(array: T[], column: string, isAsc: boolean = true): T[] {
    if (array.length <= 1) return array;

    const collator = new Intl.Collator('en', {
      numeric: true,
      sensitivity: 'base',
    });

    if (column == '') {
      return array;
    } else {
      const sorted = [...array].sort((a, b) => collator.compare(a[column], b[column]));
      return isAsc ? sorted : sorted.reverse();
    }
  }

  public performCsvDownload(csvData: string, fileName: string): void {
    if (!csvData) {
      throw new Error('No CSV Data');
    }
    if (!fileName) {
      throw new Error('Filename not given');
    }
    const downloadLink = document.createElement('a');
    downloadLink.target = '_blank';

    const encodedURI = encodeURI(csvData);
    const fixedEncodedURI = encodedURI.replaceAll('#', '%23');
    downloadLink.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + fixedEncodedURI);

    if (fileName) {
      downloadLink.setAttribute('download', fileName);
    }
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  public getUniqueObjects<T extends object>(allValues: T[]): T[] {
    const uniqueArray: T[] = [];
    allValues.forEach((value) => {
      if (!uniqueArray.length) {
        uniqueArray.push(value);
      } else {
        const same = uniqueArray.find((uniqueValue) => this.objectsAreSame(value, uniqueValue));
        if (!same) {
          uniqueArray.push(value);
        }
      }
    });
    return uniqueArray;
  }

  public objectsAreSame<T extends object>(x: T, y: T): boolean {
    let objectsAreSame = true;
    for (const propertyName in x) {
      if (x[propertyName] !== y[propertyName]) {
        objectsAreSame = false;
        break;
      }
    }
    return objectsAreSame;
  }

  public deepCopy<T>(object: T): T {
    return JSON.parse(JSON.stringify(object));
  }

  public getDateAtMidnight(date: Date): Date {
    const newDate = new Date(date);
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    return newDate;
  }

  public observableToPromise<T>(observable: Observable<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      observable.subscribe({ next: resolve, error: reject });
    });
  }

  public sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public static capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  public static capitalizeAndReplaceUnderscores(text: string): string {
    if (!text) return '';
    const headerWords = text.split('_');
    const capitalized = headerWords.map((word) => this.capitalizeFirstLetter(word));
    return capitalized.join(' ');
  }
}

export enum RoleEnum {
  ADMIN = 'admin',
  CSC_ADMIN = 'csc_admin',
}
