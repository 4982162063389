import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';

import { AppFormGroup } from '../../../utils/app-form-group';
import { CustomValidators } from '../../../utils/custom-validators';

import { AlertService } from './../../../shared/services/alert/alert.service';
import { AuthService } from '../../../services/auth/auth.service';
import {
  Breadcrumb,
  BreadcrumbService,
} from '../../../shared/services/breadcrumb/breadcrumb.service';
import { LoadingService } from './../../../shared/services/loading/loading.service';
import { SyncService } from '../../../services/sync/sync.service';
import { TranslateService } from '../../../services/translate/translate.service';

import { Customer } from '../../../models/customer.model';
import { Survey } from '../../../models/survey.model';
import { Image } from '../../../shared/components/image-input/models/image';

import * as CscActions from '../../../state/csc/csc.actions';
import * as CscSelectors from '../../../state/csc/csc.selector';
import * as CustomerActions from '../../../state/customer/customer.actions';
import * as SurveyActions from '../../../state/survey/survey.actions';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrl: './add-customer.component.scss',
})
export class AddCustomerComponent {
  public customer: Customer = new Customer();
  public image = new Image();
  public form: AppFormGroup = new AppFormGroup({
    name: new FormControl('', [Validators.required, CustomValidators.cannotBeWhitespace]),
  });

  public cscs$ = this.store.select(CscSelectors.selectAllCscs);

  public constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private breadcrumbService: BreadcrumbService,
    private loadingService: LoadingService,
    private router: Router,
    private store: Store,
    private syncService: SyncService,
    public t: TranslateService
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(CscActions.fetchCscs());
    this.initBreadcrumb();
  }

  private initBreadcrumb(): void {
    const crumb = new Breadcrumb('Add New Customer', ['/surveys/add-customer']);
    this.breadcrumbService.pushBreadcrumb(crumb);
  }

  public async onSave(): Promise<void> {
    this.form.markAsSubmitted();

    if (this.form.invalid || !this.customer.cscId) return;

    this.loadingService.show();
    try {
      const customer = new Customer();
      customer.id = uuidv4();
      customer.cscId = this.customer.cscId;
      customer.hasAmmcareConnect = this.customer.hasAmmcareConnect;
      customer.customer_name = this.customer.customer_name;

      this.store.dispatch(CustomerActions.addCustomerSuccess({ customer }));
      await this.syncService.trySyncUpToApi();

      if (customer.id) await this.createSurvey(customer);
    } catch (err) {
      this.alertService.handleHttpError(err);
    }
    this.loadingService.hide();
  }

  private async createSurvey(customer: Customer): Promise<void> {
    const survey = new Survey();
    survey.customerId = customer.id;
    survey.user = { email: this.authService.getUser().email };
    survey.id = uuidv4();

    this.store.dispatch(SurveyActions.addSurveySuccess({ survey }));
    await this.syncService.trySyncUpToApi();
    this.breadcrumbService.popBreadcrumb();
    this.router.navigate(['/', 'surveys', survey.id]);
  }
}
