import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler } from '@angular/common/http';
import { HttpRequest, HttpInterceptor } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { MsalTokenService } from '../services/msal-token/msal-token.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  public constructor(private msalTokenService: MsalTokenService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return from(this.msalTokenService.getToken()).pipe(
      switchMap((token) => {
        if (!token) return next.handle(request);

        return next.handle(this.addAuthTokenToRequest(request, token));
      })
    );
  }

  private addAuthTokenToRequest(
    request: HttpRequest<unknown>,
    token: string
  ): HttpRequest<unknown> {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });
  }
}
